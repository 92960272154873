import React, { useEffect, useRef, useState } from 'react';
import TabIlsfaqs from './tab-ilsfaqs';
import Tabotherfaqs from './tab-otherfaqs';
import { syndDataImprovementGraphicExportAsPdf } from '../../service/utils';

const AsideFAQ = (props) => {

  
  const asideRef = useRef();
  const btnRef = useRef();
  const closeRef = useRef();
  const imageRef = useRef();

  const ilsTabRef = useRef();
  const otherTabRef = useRef();
  const tabRef = useRef();
  const tipsTricksAside = useRef();
  const tipsTricksCloseRef = useRef();
  const helpAside = useRef();
  const [ilsFaqsAsideStatus, setIlsFaqsAsideStatus] = useState(false);

  useEffect(() => {
    btnRef.current.addEventListener('click', function() {
      setIlsFaqsAsideStatus(ilsFaqsAsideStatus=>!ilsFaqsAsideStatus);
      toggleTab('other'); 
      toggleTab('ilsfaqs');
      asideRef.current.open();
    });
    closeRef.current.addEventListener('click', function() {
      asideRef.current.close();
    });
    helpAside.current.addEventListener('click', function() {
      tipsTricksAside.current.open();
    });

    tipsTricksCloseRef.current.addEventListener('click', function() {
        tipsTricksAside.current.close();
    });
    // --- TAB HANDLING ---
    // helper function
    const toggleTab = (name) => {
      setIlsFaqsAsideStatus(ilsFaqsAsideStatus=>!ilsFaqsAsideStatus);
        tabRef.current.activeTab = name;
        if (name === 'ilsfaqs') {
            ilsTabRef.current.style.display = 'block';
            otherTabRef.current.style.display = 'none';
        } else {
            ilsTabRef.current.style.display = 'none';
            otherTabRef.current.style.display = 'block';
        }
    };
    
    // initial state of tabs
    const tabEl = tabRef.current;
    tabEl.tabs = [{ label: 'ILS FAQs',  name: 'ilsfaqs' }, { label: 'Other FAQs', name: 'other' }];
    toggleTab('ilsfaqs');

    // tab changes
    tabEl.addEventListener('raulTabChange', (e) => {
        toggleTab(e.detail);
    });

    // --- POPULATE DATA ---
    
    }, []);
    
    return (
    <div class="r-font-roboto">

      <raul-button variant = "control" size = "small" id="medium" class="r-float-right" ref={btnRef}><raul-icon icon="question-circle" kind ="consumer" class="r-icon-sm"></raul-icon>ILS FAQs</raul-button>

      <raul-aside id="medium-aside" size="large" ref={asideRef}>   
        <raul-aside-header class="r-pt-4 r-pb-4">            
          <div class="r-pb-5">
            <raul-aside-title class="r-font-bold">ILS FAQs
              <div class="r-float-right r-text-primary r-mr-2 r-text-sm r-p-2 nested-aside-show-button r-cursor-pointer" ref={helpAside}>
                    <raul-icon icon="help-center" kind="resource" class="r-text-md r-mr-2"></raul-icon>
                    Helpful Tips
              </div> 
            </raul-aside-title>
          </div>
        <raul-tabs ref={tabRef}></raul-tabs>     
        </raul-aside-header>

        <raul-aside-body>
            <raul-tab-pane name="ilsfaqs" class="tabs-pane" ref={ilsTabRef}>                  
                <TabIlsfaqs faqs={props.ilsFaq} ilsFaqsAsideStatus = {ilsFaqsAsideStatus} />
            </raul-tab-pane>
            <raul-tab-pane name="otherfaqs" class="tabs-pane" ref={otherTabRef}>
                <Tabotherfaqs faqs={props.otherFaq} ilsFaqsAsideStatus = {ilsFaqsAsideStatus} />
            </raul-tab-pane>
        </raul-aside-body>

        <raul-aside-footer>
          <raul-aside-actions>
            <raul-aside-actions>
              <raul-button class="close-aside" variant="primary" id="medium-aside-close" ref={closeRef}>
                Close
              </raul-button>           
            </raul-aside-actions>
          </raul-aside-actions>
        </raul-aside-footer>
        <raul-aside id="tips-tricks-aside" slot="secondary-aside" size="large" ref={tipsTricksAside}>
          <raul-aside-header class="r-bg-gray-lightest">
              <raul-aside-title><span>Tips & Tricks</span>
              <raul-button variant = "control" size = "small" id="tipsExport" class="r-float-right r-mt-0" onClick={()=>syndDataImprovementGraphicExportAsPdf(imageRef)}  >
                  <raul-icon icon="download-bottom" kind ="consumer" class="r-icon-sm"></raul-icon>
                  Export
              </raul-button>
          </raul-aside-title>

          </raul-aside-header>

          <raul-aside-body class="r-pl-0">
              <img src="images/SyndDataImprovementGraphic.png" alt="BigCo Inc. logo" ref={imageRef} />
          </raul-aside-body>

          <raul-aside-footer>
          <raul-aside-actions>
              <raul-button class="nested-aside-close" variant="secondary" id="tips-tricks-aside-close" ref={tipsTricksCloseRef}>
              Close
              </raul-button>
          </raul-aside-actions>
          </raul-aside-footer>
      </raul-aside>
    </raul-aside>
  </div>
  );
};

export default AsideFAQ;
