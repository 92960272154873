
const AppConfig = {
    backendUrl: "#{API_HOST}#",
    uri: {
        'authenticate': '/api/v1/token',
        'refreshToken': '/api/v1/refresh',
        'company': '/api/v1/company/{id}',
        'dashboard': '/api/v1/dashboard?pKey={pkey}&companyId={companyId}&contactId={contactId}&pageSize={pageSize}&pageNumber={pageNumber}',
        'property': '/api/v1/property/{pkey}',
        'faqIls': '/api/v1/faq/ils',
        'faqOther': '/api/v1/faq/other',
        'allFaqs':'/api/v1/faq/all',
        'updateProduct': '/api/v1/property/product',
        'leadChannels' : '/api/v1/lead-channel?propertyId={id}',
        'deleteLeadChannel' : '/api/v1/lead-channel?propertyId={id}&leadChannelId={lid}&uid={contactId}',
        'updateLeadChannel' : '/api/v1/lead-channel?propertyId={id}&uid={contactId}',
        'sendEmailNotification' : '/api/v1/dashboard/send-email?uid={contactId}',
        'emailSubscription' : '/api/v1/dashboard/email-subscription/{companyId}',
        'pickUpTimes' : '/api/v1/lead-channel/{channelId}/pickup-times'
    }
};

export default AppConfig;
