import { createContext, useCallback, useEffect, useState } from 'react';
import './App.css';
import Dashboard from './page/dashboard';
import AppLoader from './component/loader/loader';
import HttpService from './service/http';
import Alert from './component/alert/alert';
import AppConfig from './app-config';
import { useDispatch } from "react-redux";
import { ilsFaqsLoadData, otherFaqsLoadData } from "./store";



const appContext = createContext({ auth: null, http: HttpService, params: {} });
const loaderContext = createContext({ loader: { isLoading: false}, showLoader: () => {} });

const App = () => {
  let dispatch = useDispatch();

  // config loader
  const showLoader = useCallback(
    (loading) => setLoader({ loader: { isLoading: loading }, showLoader }),
    []
  );
  const [loader, setLoader] = useState({ loader: { isLoading: false }, showLoader });

  const updateContext = useCallback(
    (auth, params) => setContext({ auth: auth, http: {...HttpService, token: auth.token, user:auth.user, params: params, showLoader: showLoader, updateInstance: updateContext}, params: params }),
    [showLoader]
  );
  const [context, setContext] = useState({ auth: null, http: HttpService, params: {} }, updateContext);

  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState();
  // authenticate
  useEffect(() => {
    // PARSE INITIAL PARAMS (pkey, company id, etc.)
    var params = { };
    if (window.location.search && window.location.search.length > 3) {
      const query = window.location.search.replace('?', '').split('&');
      for (var i=0; i < query.length; i++) {
        var p = query[i].split('=');
        params[p[0]] = p[1];
      }
    }
    // authenticate
    showLoader(true);
    HttpService.authenticate(params)
      .then((response) => {
        if (response && response.token) {
          updateContext(response, params);
          showLoader(true);
          setAuthenticated(true);
        } else {
          setError('Unable to create authenticated session.');
        }
      })
      .catch((error) => { 
        setError(error); 
      })
      .finally(() => {
        showLoader(false);
      });
      HttpService.callApi('GET', AppConfig.uri.allFaqs).then((response)=> {
        dispatch(ilsFaqsLoadData(response.syndicationIlsFaq));
        dispatch(otherFaqsLoadData(response.syndicationOtherFaq)); 
      })
  }, [showLoader, updateContext]);
  

  return (
    <appContext.Provider value={context}>
    <loaderContext.Provider value={loader}>
      <div className="synd-app">
        <AppLoader />
        { authenticated ? <Dashboard /> : 
          error ? (<Alert message={error} />)
                : (<Alert message="Creating authenticated session" title="Authenticating" type="information" />)
        }
      </div>
    </loaderContext.Provider>
    </appContext.Provider>
  );
}

export { appContext };
export { loaderContext };
export default App;
