import React, { useEffect, useRef, useState } from 'react';
import Export from './export';

const Tabotherfaqs = (props) => {

  const htmlRef = useRef();
  const otherIlsFaqsRef = useRef();
  const otherIlsFaqs = { text: 'Select Other FAQ', value: '' };
  const [selectedOtherFaq, setSelectedOtherFaq] = useState(null);

  const handleIlsChange = (event) => {
    const selectedTopic = event === ""? event : event.target.value;
    const selectedOtherFaq = props.faqs?.find((faq) => faq.topic === selectedTopic);
    if (selectedOtherFaq){
      if(selectedOtherFaq.html) {
        setTimeout(() => htmlRef.current.innerHTML = selectedOtherFaq.html, 1);    
      }else{ 
        setTimeout(() => htmlRef.current.innerHTML ="", 1);
      }
    }
    setSelectedOtherFaq(selectedOtherFaq);
  };

  useEffect(() => {
    handleIlsChange("")
    otherIlsFaqsRef.current.value = "";
  },[props.ilsFaqsAsideStatus]);

  useEffect(() =>{
    otherIlsFaqsRef.current.options = props.faqs ? [otherIlsFaqs].concat(getIlsDetails(props.faqs)) : [otherIlsFaqs];
    otherIlsFaqsRef.current.value = otherIlsFaqs.value;
    otherIlsFaqsRef.current.addEventListener("raulChange", (e) => {
      handleIlsChange(e);
    });
  },[]);

  const getIlsDetails = (ilsdetails) => {
    let ilsFaqs = [];
    for (var ils of ilsdetails) {
      ilsFaqs.push({ text: ils.topic, value: ils.topic });
    }
    ilsFaqs.sort((a, b) => a.value.localeCompare(b.value));
    return ilsFaqs;
  };

  return (
    <div className="company-tab">
      <div className="filters-container r-mt-3">
        <div class="r-flex">
          <div class="r-w-1/2 r-ml-1">
           <p class="r-w-64 r-text-sm r-leading-loose">OTHER FAQ Topics</p>
            <div class="r-w-64 r-mb-10">
              <raul-select placeholder="LeadChannel" class="r-font-normal" ref={otherIlsFaqsRef}></raul-select>
            </div>
          </div>
          <div class="r-w-1/2 r-floar-right">
          {selectedOtherFaq && (<Export type={"Other"} name={"Other FAQs for the channel"} class={'.other-print-only'} id={"other-data"}></Export>)}
          </div>
        </div>
      </div>
      {selectedOtherFaq && (
      <div id="other-data">
          <div className="other-print-only"><h2 class="r-font-bold r-font-robot r-pb-3">Selected OTHER FAQ: {selectedOtherFaq.topic}</h2></div>
          { selectedOtherFaq.question &&
            <div>
              <p>        
               <span class="r-font-bold r-block r-pb-2">Q: {selectedOtherFaq.question}</span>
              </p>
              <p>
              <span class="r-mb-4 r-pt-2 r-leading-normal r-font-thin">A: {selectedOtherFaq.answer}</span>
              </p>
              <div class="r-pt-3" ref={htmlRef} /> 
            </div>
          }
      </div>
       )}
    </div>
  );
};

export default Tabotherfaqs;