import { useContext } from 'react';
import './loader.css';
import { loaderContext } from '../../App';

const AppLoader = () => {

    const { loader } = useContext(loaderContext);
    if (!loader || !loader.isLoading) {
        return <div id="loaderPlaceHolder" />
    }

    return (
        <div className="app-loader">
            <raul-page-loader />
        </div>
    );
};

export default AppLoader;