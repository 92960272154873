const Export = (props) => {
    const printContent = () => {
        const hiddenPrintContainer = document.createElement('div');
        hiddenPrintContainer.style.display = 'none';
      
        const selectedOption = document.querySelector('.dropdown option:checked');
        const selectedOptionText = selectedOption ? selectedOption.innerText : 'No option selected';
      
        const selectedHeader = document.createElement('h2');
        selectedHeader.className = 'r-font-bold r-text-md print-only';
        selectedHeader.textContent = props.type === 'Other' ? props.name : props.name+ ''+selectedOptionText;
        hiddenPrintContainer.appendChild(selectedHeader);
      
        const dataElement = document.getElementById(props.id);
        if (dataElement) {
          const faqsCopy = dataElement.cloneNode(true);
          const selectedHeaderCopy = faqsCopy.querySelector(props.class);
          if (selectedHeaderCopy) {
            faqsCopy.removeChild(selectedHeaderCopy);
          }
          hiddenPrintContainer.appendChild(faqsCopy);
        }
      
        const printDocument = document.createElement('iframe');
        printDocument.style.display = 'none';
        document.body.appendChild(printDocument);
        
        const printDocumentWindow = printDocument.contentWindow || printDocument.contentDocument.window;
        printDocumentWindow.document.open();
        printDocumentWindow.document.write(`
          <!DOCTYPE html>
          <html>
          <head>
            <style> 
              .r-font-bold { font-weight: bold; }
              .r-text-md { font-size: 16px; }
            </style>
          </head>
          <body>
            ${hiddenPrintContainer.innerHTML}
          </body>
          </html>
        `);
        printDocumentWindow.document.close();
        printDocumentWindow.print();
      
        document.body.removeChild(printDocument);
      };
    
      return (
        <raul-button variant = "control" size = "small" id="medium" class="r-float-right r-mt-0" onClick={printContent}>
            <raul-icon icon="download-bottom" kind ="consumer" class="r-icon-sm"></raul-icon>Export
        </raul-button>
      );    
}
export default Export;