import React, { useEffect, useState, useRef } from 'react';
import Export from './export';

const TabIlsfaqs = (props) => {
  
  const ilsFaqsRef = useRef();
  const ilsFaqs = { text: 'Select ILS FAQ', value: '' };
  const [selectedFaq, setSelectedFaq] = useState(null);
  const handleIlsChange = (event) => {
    const selectedIls = event === ""? event : event.target.value;
    const selectedFaq = props.faqs?.find((faq) => faq.ilsName === selectedIls);
    setSelectedFaq(selectedFaq);
  };
  useEffect(() => {
    handleIlsChange("")
    ilsFaqsRef.current.value = "";
    ilsFaqsRef.current.options = props.faqs ? [ilsFaqs].concat(getIlsDetails(props.faqs)) : [ilsFaqs];
    ilsFaqsRef.current.value = ilsFaqs.value;
    ilsFaqsRef.current.addEventListener("raulChange", (e) => {
      handleIlsChange(e);
    });
  },[props.ilsFaqsAsideStatus]);

  

  const getIlsDetails = (ilsdetails) => {
    let ilsFaqs = [];
    for (var ils of ilsdetails) {
      ilsFaqs.push({ text: ils.ilsName, value: ils.ilsName });
    }
    ilsFaqs.sort((a, b) => a.value.localeCompare(b.value));
    return ilsFaqs;
  };
  

  return (
    <div className="company-tab">
      <div className="filters-container r-mt-3">
        <div class="r-flex">
          <div class="r-w-1/2 r-ml-1">
            <p class="r-w-64 r-text-sm r-leading-loose">ILS FAQ Topics</p>
            <div class="r-w-64 r-mb-10">
              <raul-select placeholder="LeadChannel" class="r-font-normal" ref={ilsFaqsRef}></raul-select>
            </div>   
          </div>
          <div class="r-w-1/2 r-floar-right">
          {selectedFaq && (<Export type={"Ils"} name={"Selected ILS FAQ:"} class={'.ils-print-only'} id={"ils-data"}></Export>)}
          </div>
        </div>
      </div>
      {selectedFaq && (
        <div id="ils-data">
          <div className="ils-print-only"><h2 class="r-font-bold r-font-robot r-pb-3">Selected ILS FAQ: {selectedFaq.ilsName}</h2></div>
          {selectedFaq.faqList.map((faq, index) => (  
            <div key={index} class="r-pb-5">  
              <p>        
              <span class="r-font-bold r-block r-pb-2">Q: {faq.question}</span>
              </p>
              <p>
              <span class="r-mb-4 r-pt-2 r-leading-normal r-font-thin">A: {faq.answer}</span>
              </p>
              {/* <hr class="line"></hr> */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TabIlsfaqs;