import {configureStore, createSlice} from "@reduxjs/toolkit";
import _ from "lodash";
const dashboardData = null;
const ilsFaqs = null;
const otherFaqs = null;

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState : dashboardData,
    reducers : {
        dashboardInitialState : (state, action)=> {
            state = action.payload;
            return state;
        },
        dashboardDeletePropertyChannelById : (state, action)=> {
            const {propertyId, leadChannelId} = action.payload;
            const property = _.find(state.propertyList, {propertyId : propertyId});
            if(property){
                _.remove(property.channelList, {leadChannelId : leadChannelId})    
            }
            return state;
        },
        dashboardAddPropertyChannelById : (state, action)=> {
            const {propertyId, channels} = action.payload;
            _.forEach(channels, function(data){
                let channelData = {
                    leadChannelId : data.id,
                    leadChannelName : data.name
                };
                const property = _.find(state.propertyList, {propertyId : propertyId});
                if(property){
                    if(property.channelList === null) {
                        property.channelList = [];
                    } 
                    property.channelList.push(channelData);  
                }
            });
            return state;
        }
    }
});
const ilsFaqsSlice = createSlice({
    name: "ilsFaqs",
    initialState : ilsFaqs,
    reducers : {
        ilsFaqsLoadData : (state, action) => {
            state = action.payload;
            return state;
        } 
    }
});

const otherFaqsSlice = createSlice({
    name: "otherFaqs",
    initialState : otherFaqs,
    reducers : {
        otherFaqsLoadData : (state, action) => {
            state = action.payload;
            return state;
        } 
    }
});

const store = configureStore({
    reducer : {
        dashboard : dashboardSlice.reducer,
        ilsFaqs : ilsFaqsSlice.reducer,
        otherFaqs : otherFaqsSlice.reducer
    }
});


export const {dashboardInitialState, dashboardDeletePropertyChannelById, dashboardAddPropertyChannelById} = dashboardSlice.actions;
export const {ilsFaqsLoadData} = ilsFaqsSlice.actions;
export const {otherFaqsLoadData} = otherFaqsSlice.actions;

export default store;
