import AppConfig from "../app-config";

const HttpService = {

    token: null,
    params: {},
    showLoader: () => {},

    getUrl: function(uri, data) {
        var url = AppConfig.backendUrl + uri
            .replace('{pkey}', this.params.pkey ? this.params.pkey : '')
            .replace('{companyId}', this.user ? this.user.companyId : '0')
            .replace('{contactId}', this.user ? this.user.id : '0');

        if (data) {
            for (const [key, value] of Object.entries(data)) {
                url = url.replace('{' + key + '}', value);
            }
        }
        return url;
    },
    getHeaders: function () {
        var header = {
            "Content-Type": "application/json",
        };

        if (this.token) {
            header["Authorization"] = "Bearer " + this.token.token;
        }
        return header;
    },
    verifyStatus: (response) => {
        if (response.ok) {
            return;
        }
        if (response.statusText) {
            return response.statusText;
        }
        if (response.status === 403) {
            return "Access denied."
        }
        return "Http call returned status " + response.status;
    },
    handleSuccess: (response) => {
        if (response.errors) {
            return Promise.reject(response.errors[0].message);
        }
        return response.data;
    },
    handleError: (error) => {
        return Promise.reject(error);
    },
    refreshToken: async function() {
        const now = (new Date()).getTime();

        // check expiration time with 10s buffer
        if (this.token.expiry - 10000 < now) {
            await this.callApi('POST', AppConfig.uri.refreshToken, this.token, true)
                  .then((response) => {
                    if (response.token.expiry > now) {
                        this.token = response.token;
                    }
                  })
                  .catch(this.handleError);
        }
    },
    callApi: async function(method, uri, data, isRefresh) {
        this.showLoader(true);

        if (AppConfig.mockApi) {
            var mockUri = uri;
            if (uri.indexOf('?') >= 0) {
                mockUri = uri.slice(0, uri.indexOf('?'))
            }

            return await fetch('./json' + mockUri + '/success.json')
                .then((httpResponse) => {
                    const error = this.verifyStatus(httpResponse);
                    if (error) {
                        return Promise.reject(error);
                    }
                    return httpResponse.json()
                        .then(this.handleSuccess)
                        .catch(this.handleError)
                        .finally(() => this.showLoader(false))
                })
        }

        if (!isRefresh && this.token) {
            await this.refreshToken();
        }

        return await fetch(this.getUrl(uri, data), {
            method: method,
            body: ((method === 'POST' || method === 'PUT' ) && data) ? JSON.stringify(data) : undefined,
            headers: this.getHeaders()
        })
        .then((httpResponse) => {
            return httpResponse.json()
                .then(this.handleSuccess)
                .catch(this.handleError);
        })
        .catch(this.handleError)
        .finally(() => this.showLoader(false));
    },

    authenticate: async function (data) {
        return await this.callApi('POST', AppConfig.uri.authenticate, data);
    }
};

export default HttpService;