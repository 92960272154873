import { useEffect, useRef } from 'react';
import './alert.css';

const Alert = (params) => {

    // RAUL element
    const alertRef = useRef();
    useEffect(() => {
        // unwrap params
        var values = params;
        if (params.params) {
            values = params.params;
        }

        if (alertRef && alertRef.current && values && values.hide) {
            setTimeout(() => {
                values.hide();
            }, 5000);
            alertRef.current.addEventListener('click', (e) => {
                values.hide();
            });
        }
    }, [params]);

    // unwrap params
    var values = params;
    if (params.params) {
        values = params.params;
    }

    var message = values.message;
    var title = values.title || "Error!";
    var type  = values.type || "danger";

    if (!message) {
        return <div />
    }
    return (
        <div className="app-alert" ref={alertRef}>
            <raul-alert heading={title} content={message} rounded variant={type} />
        </div>
    );
};

export default Alert;