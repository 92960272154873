import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
let properyMissingDataList = [];
//Export missing fields as Excel (xslt)
export function exportMissingFileds(props, type){
    properyMissingDataList = [];
    if(type === 'company'){
        props.propertyList.map(property => {
            preparePropertyMap(property);
        });
    }  else{
        preparePropertyMap(props);
    }
    let fileName = type === 'company'? props.companyName : props.propertyName;
    let allowedChacters = /^[1-zA-Z0-9 _-]*$/;
    fileName = fileName.split('').filter(char => allowedChacters.test(char)).join('');
    let sheetName = fileName.slice(0, 31);
    saveExportMissingFileds(properyMissingDataList, sheetName, fileName);
}
function preparePropertyMap(property) {
    const missingData = {};
    missingData.propertyId = property.propertyId;
    missingData.propertyName = property.propertyName;
    missingData.percentage = property.completenessScore.propertyScore;
    missingData.missingFields = property.completenessScore.missingFieldList.join(", ");
    properyMissingDataList.push(missingData);
}

async function saveExportMissingFileds(properyMissingDataList, sheetName, fileName) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const newSheet = workbook.sheet(0).name(sheetName);
        const sheetData = getExportMissingFiledsDataSheet(properyMissingDataList);
        const totalColumns5 = sheetData[0].length;
        newSheet.cell("A1").value(sheetData);
        const range10 = newSheet.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns5);
        newSheet.row(1).style("bold", true);
        newSheet.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range10.style("border", true);
        return workbook.outputAsync().then((res) => {
            saveAs(res, fileName +"_Missing_Syndication_Data.xlsx");
        });
    });
}

function getExportMissingFiledsDataSheet(data) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
        return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] === '0' ? 0 : row[fieldName] : "";
        });
    });
    sheetData.unshift([ "Property Id", "Property Name", "Percentage", "Missing fields (comma separated)"]);
    return sheetData;
}

//Helpful trips and tricks download as PDF
export function syndDataImprovementGraphicExportAsPdf(imageRef) {
    const input = imageRef.current;
    html2canvas(input).then((canvas) => {
        const imageData = canvas.toDataURL('images/SyndDataImprovementGraphic.png');
        const pdf = new jsPDF('p', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('syndDataImprovementGraphic.pdf');
    });
}